import React from "react";
import {
  axiosApiBackend,
  axiosApiBackendNoJson,
} from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import List from "@mui/icons-material/List";
// import Edit from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Description from "@mui/icons-material/Description";
// import Delete from "@mui/icons-material/Delete";
import CloudDownload from "@mui/icons-material/CloudDownload";

// Services
import SearchBarService from "services/SearchBarService";

// core components
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { withUserContext } from "context/UserContext";
import Divider from "@mui/material/Divider";
import FileUtils from "Utils/FileUtils";
import Render from "Utils/RenderUtils";
import EsignatureUtils from "Utils/EsignatureUtils";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import HistoryEdu from "@mui/icons-material/HistoryEdu";
import Group from "@mui/icons-material/Group";

class EsignatureView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    // this.CAN_EDIT = this.USER.can("edit.esignature");
    // this.CAN_DELETE = this.USER.can("delete.esignature");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/esignatures";
    this.FRONT_URL = "/esignatures";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      signataires: [],
      documents: [],
      // DATA FORM
      signable_type: "",
      signable_id: "",
      section: "",
      identifiant: "",
      titre: "",
      statut: "",
      date_creation: "",
      date_modification: "",
      date_envoi: "",
      date_complete: "",
      // END DATA FORM
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  clearAlert = () => {
    this.setState({ alert: null });
  };

  alertError = (title, errorMessage) => {
    return (
      <AlertDialog
        title={title}
        onCancel={this.clearAlert}
        cancelLabel="OK"
        cancelColor="error"
      >
        {errorMessage}
      </AlertDialog>
    );
  };
  /*
  clearAlert = () => {
    this.setState({ alert: null });
  };


  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const entities = err.response.data;
          this.onError(entities);
        }
      });
  };

  onError = ( entities) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer cette Esignature : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {entities.logement > 0 && (
              <li>{entities.logement} logement(s) associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le Bénéficiaire ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };
  */

  downloadFile = (documentId, signed) => {
    this.setState({ downloading: true });

    const { id } = this.state;

    axiosApiBackendNoJson
      .get(`${this.BACKEND_URL}/${id}/documents/${documentId}/download`, {
        responseType: "blob",
        params: {
          signed: signed ? 1 : 0,
        },
      })
      .then((res) => {
        this.setState({ downloading: false });
        FileUtils.downLoadFile(res.data, res.headers, "fichier");
      })
      .catch((error) => {
        const titleError = "Erreur de téléchargement du fichier";
        const errMessage =
          error.response.status === 404
            ? "Le fichier est introuvable"
            : "Une erreur est survenue côté serveur";

        this.setState({
          downloading: false,
          alert: this.alertError(titleError, errMessage),
        });
      });
  };

  render() {
    const {
      signable_type,
      signable_id,
      signable_structure_locale_nom,
      identifiant,
      titre,
      statut,
      date_creation,
      date_modification,
      date_envoi,
      date_complete,
      signataires,
      documents,
    } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <HistoryEdu />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Esignature
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Entitée de référence : </strong>
                <DisableLink
                  to={EsignatureUtils.getSignableFrontUrl(
                    signable_type,
                    signable_id,
                  )}
                >
                  {EsignatureUtils.renderSignable(
                    signable_type,
                    signable_id,
                    signable_structure_locale_nom,
                  )}
                </DisableLink>
              </Typography>
              <Typography>
                <strong>Identifiant : </strong>
                {identifiant}
              </Typography>
              <Typography>
                <strong>Titre : </strong>
                {titre}
              </Typography>
              <Typography>
                <strong>Statut : </strong>
                {statut}
              </Typography>
              <Typography>
                <strong>Date de création : </strong>
                {Render.dateTime(date_creation)}
              </Typography>
              <Typography>
                <strong>Dernière mise à jour : </strong>
                {Render.dateTime(date_modification)}
              </Typography>
              <Typography>
                <strong>Date d&apos;envoi : </strong>
                {Render.dateTime(date_envoi)}
              </Typography>
              <Typography>
                <strong>Date de complétion : </strong>
                {Render.dateTime(date_complete)}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <InsertDriveFile />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Documents
                </Typography>
              }
            />
            <CardContent>
              {documents.map((document, key) => (
                <React.Fragment key={key}>
                  {Boolean(key) && <Divider sx={{ my: 1 }} />}
                  <Typography variant="h5">Document {key + 1}</Typography>
                  <Typography>
                    <strong>Identifiant : </strong>
                    {document.identifiant}
                  </Typography>
                  <Typography>
                    <strong>Fichier non signé : </strong>
                    {document.nom_fichier_non_signe}{" "}
                    {document.nom_fichier_non_signe != null && (
                      <Button
                        square
                        size="small"
                        onClick={() => this.downloadFile(document.id, false)}
                      >
                        <CloudDownload />
                      </Button>
                    )}
                  </Typography>
                  <Typography>
                    <strong>Fichier signé : </strong>
                    {document.nom_fichier_signe}{" "}
                    {document.nom_fichier_signe != null && (
                      <Button
                        square
                        size="small"
                        onClick={() => this.downloadFile(document.id, true)}
                      >
                        <CloudDownload />
                      </Button>
                    )}
                  </Typography>
                </React.Fragment>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Group />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Signataires
                </Typography>
              }
            />
            <CardContent>
              {signataires.map((signataire, key) => (
                <React.Fragment key={key}>
                  {Boolean(key) && <Divider sx={{ my: 1 }} />}
                  <Typography variant="h5">
                    {signataire.prenom} {signataire.nom}
                  </Typography>
                  <Typography>
                    <strong>Texte d&apos;ancrage : </strong>
                    {signataire.anchor_string}
                  </Typography>
                  <Typography>
                    <strong>Identifiant : </strong>
                    {signataire.identifiant}
                  </Typography>
                  <Typography>
                    <strong>Email : </strong>
                    {Render.email(signataire.email)}
                  </Typography>
                  <Typography>
                    <strong>Statut : </strong>
                    {signataire.statut}
                  </Typography>
                  <Typography>
                    <strong>Envoi : </strong>
                    {Render.dateTime(signataire.date_envoi)}
                  </Typography>
                  <Typography>
                    <strong>Lu : </strong>
                    {Render.dateTime(signataire.date_lu)}
                  </Typography>
                  <Typography>
                    <strong>Signature : </strong>
                    {Render.dateTime(signataire.date_signature)}
                  </Typography>
                </React.Fragment>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {/* {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )} */}
          {/* {this.CAN_DELETE && this.state.id && (
            <Button
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )} */}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={this.FRONT_URL + "/logs?type=Esignature&id=" + this.state.id}
            >
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

EsignatureView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(EsignatureView);
